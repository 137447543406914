import React from 'react';
import { STEAM_URL } from '../utils';
import { Link, Trans } from 'gatsby-plugin-react-i18next';

export const buttonDefaultStyle = "rounded px-5 py-2 bg-indigo-600 text-indigo-50 hover:text-fl-white uppercase text-sm tracking-wider disabled:bg-neutral-500 hover:bg-indigo-500 hover:cursor-pointer disabled:cursor-not-allowed";

export function WishlistButton({ className = '' }) {
	let classes = 'inline-block px-5 py-3 rounded shadow-xl shadow-fl-highlight-light/40';
	classes += ' border border-indigo-500 outline outline-2 outline-fl-turkis-dark';
	classes += ' bg-gradient-to-l to-fl-turkis-dark from-indigo-500 text-fl-white animate-pulse transition-all';
	classes += ' hover:border-fl-white/40 hover:animate-none hover:translate-x-1';
	classes += ' ' + className;

	function onClick(e) {
		if (STEAM_URL === '') {
			e.preventDefault()
		}
	}

	return (
		<a href={STEAM_URL} target="_blank" rel="noreferrer" className={classes} onClick={onClick}>
			<p className="flex items-center">
				<img src="/assets/icon_steam.png" alt="Steam Icon" className="mr-2" />
				<span className="text-lg tracking-wide">
					<Trans i18nKey="wishlist">
						Add to Wishlist
					</Trans>
					<small className="text-xs ml-1 opacity-70">(Steam)</small>
				</span>
			</p>
		</a >
	)
}

export function Button({ onClick, disabled, children, className = '' }) {
	return (
		<button
			onClick={onClick}
			className={buttonDefaultStyle + ' ' + className}
			disabled={disabled}
		>
			{children}
		</button>
	)
}

