import React from 'react';
import { WishlistButton } from '../components/Buttons';
import { Section } from '../components/Section';
import { Fade } from "react-awesome-reveal";
import { Link, Trans } from 'gatsby-plugin-react-i18next';

const moreFeatures = [
	<p className="-ml-1">
		<Trans i18nKey="feature1">
			A deeply <strong>intertwined story</strong> told from two perspectives, twists and turns included.
		</Trans>
	</p>,
	<p className="-ml-1">
		<Trans i18nKey="feature2">
			<strong>All action, no fillers!</strong> 6-8 hours of playtime.
		</Trans>
	</p>,
	<p className="-ml-1">
		<Trans i18nKey="feature3">
			<strong>Hand-crafted pixelart</strong> inspired by the classics.
		</Trans>
	</p>,
	<p className="-ml-1">
		<Trans i18nKey="feature4">
			Pet dogs! 🐶
		</Trans>
	</p>,
	<p className="-ml-1">
		<Trans i18nKey="feature5">
			<strong>16-bit soundtrack:</strong> over 40 original tracks.
		</Trans>
	</p>,
	<p className="-ml-1">
		<Trans i18nKey="feature6">
			No random encounters!
		</Trans>
	</p>
];

export function Features() {
	return (
		<div className="mt-20">
			<div className="clouds-top"></div>
			<Section bg="bg-fl-sky" color="text-fl-text-light" className="text-center">
				<div className="mx-auto max-w-md px-4 py-3">
					<h2 className="text-lg uppercase tracking-wider text-center mb-3">
						<Trans i18nKey="featureTitle">
							... And more
						</Trans>
					</h2>
					<Fade direction="up" cascade duration={500} triggerOnce>
						<ul className="list-disc ml-6 text-left sm:ml-16">
							{moreFeatures.map((f, i) => (
								<li key={i}>{f}</li>
							))}
						</ul>
					</Fade>
				</div>
				<div className="text-center mt-4">
					<WishlistButton />
				</div>
			</Section>
			<div className="clouds-bottom -mt-4"></div>
		</div>
	)
}

